// Invoy color theme
$i-blue: #2a3c56;
$i-green: #477e63;
$i-mint-green: #e4f5ec;
$i-denim-blue: #5c7fb3;
$i-oxford-blue: #e8f4fa;
$i-red: #eb5324;
$i-light-red: #FCE5DE;
$i-light-coral: #fae6d4;
$i-marigold: #fab52a;
$i-cream: #fff1d5;
$i-light-cream: #fffcf0;
$i-cream-light: #fcfaf2;
$i-beige: #dec4a6;
$i-turquoise: #79cdd1;
$i-light-turquoise: #DAF9F9;
$i-light-purple: #F1E5F4;
$i-lime-green: #F0FDDA;
$i-white: #fff;
$i-light-grey: #E8E8E8;
$i-gray-100: #f8f9fa;
$i-gray-900: #25265e;


$invoy-colors: (
  'i-blue': $i-blue,
  'i-green': $i-green,
  'i-mint-green': $i-mint-green,
  'i-lime-green': $i-lime-green,
  'i-denim-blue': $i-denim-blue,
  'i-oxford-blue': $i-oxford-blue,
  'i-red': $i-red,
  'i-light-red': $i-light-red,
  'i-light-coral': $i-light-coral,
  'i-marigold': $i-marigold,
  'i-cream': $i-cream,
  'i-light-cream': $i-light-cream,
  'i-cream-light': $i-cream-light,
  'i-beige': $i-beige,
  'i-turquoise': $i-turquoise,
  'i-light-turquoise': $i-light-turquoise,
  'i-light-purple': $i-light-purple,
  'i-white': $i-white,
  'i-light-grey': $i-light-grey,
  'i-grey-100': $i-gray-100,
  'i-grey-900': $i-gray-900,
);
