.chat-popup {
  position: fixed;
  bottom: 50px;
  right: 50px;

  > .chat-window {
    width: 378px;
    border-radius: 4px;
    margin-bottom: 20px;

    > .card-header {
      background-image: linear-gradient(105deg, #1e71d3, #348ffb);
      color: #ffffff;

      a,
      .btn-link,
      h4 {
        color: #ffffff;
      }
    }

    > .scroll-y {
      max-height: 400px;
    }
  }

  .message {
    margin-bottom: 30px;
    clear: both;

    .avatar {
      width: 40px;
      float: left;
    }
    
    .content {
      width: 85%;

      > p {
        border-radius: 5px;
        padding: 1rem;
        background-color: #f5f5f5;
      }
    }

    &.by-me {
      .content {
        float: right;

        > p {
          background-color: #edf5ff;
        }

        .by {
          text-align: right;
        }
      }
    }
  }

  .form-inline {
    .form-control {
      border: none;
      border-radius: 0 0 4px 4px;
      padding: 20px 24px;

      &::placeholder {
        color: rgba(68, 68, 81, 0.54);
      }

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }

    .form-controls {
      width: 75px;

      .upload-button {
        background: url('/image.svg') center center no-repeat;
        background-size: 27px 20px;
      }
    }
  }

  .chat-toggler {
    width: 72px;
    height: 72px;
    background-image: linear-gradient(121deg, #1e71d3, #348ffb);
    box-shadow: 0 8px 24px 0 rgba(44, 108, 227, 0.54);
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;

    &:active, &:focus {
      outline: 0;
      box-shadow: 0 8px 24px 0 rgba(44, 108, 227, 0.54);
      border: none;
    }
  }
}

