@import './invoy-theme/colors.scss';

.pagination-v2 {
  .pagination {
    justify-content: center;
    margin: 0;
    padding: 0;

    .page-item {
      a {
        color: gray;
        background-color: $i-white;
        font-weight: 500;
        font-size: 16px;
        padding: 0.5rem 0.75rem;
        border-radius: 4px;
        margin: 0 0.25rem;
        border: 1px solid $i-gray-100;

        &:hover {
          background-color: $i-green;
          color: $i-white;
          border-color: $i-green;
        }
      }
      &.active  {
        background-color: $i-white;
        color: $i-green;
        border-color: $i-green;

        .page-link {
            background-color: $i-white;
            color: $i-green;
            border-color: $i-green;
        }
      }
    }
  }
}
