@import '@fortawesome/fontawesome-svg-core/styles';
@import './invoy-theme/fonts.scss';
@import './invoy-theme/font-families.scss';
@import 'bootstrap';

.tooltip-inner {
  font-size: .75rem;
}

.navbar {
  .navbar-brand {
    font-size: 19px;
    font-weight: 900;
    letter-spacing: .5px;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #283249;
  font-weight: 500;
}

.smaller {
  font-size: 70%;
}

.smallest {
  font-size: 60%;
}

bold,
.bold {
  font-weight: 700;
}

.cursor-pointer,
[data-toggle=modal],
[data-toggle=collapse] {
  cursor: pointer;
}

legend {
  font-size: 1.3rem;
}

.subtitle {
  color: $secondary;
}

.btn-secondary {
  color: $white;

  &:hover,
  &:active {
    background-color: $secondary;
  }
}

.btn-outline-secondary {
  border-color: $secondary;

  &:hover,
  &:active {
    background-color: $secondary;
  }
}

.popover {
  max-width: 80%;
}

.text-soft {
  color: $secondary;
}

.badge-tag {
  border-radius: 19px;
  background-color: $white;
  border: solid 1px $secondary;
  padding: 8px 15px;
  font-size: 12px;
  color: $secondary;
  font-weight: normal;

  &.bold {
    font-weight: 700;
  }

  &.active {
    background-color: #318EF6;
    color: $white;
    border-color: $white;
  }

  &.assigned {
    background-color: $green;
    color: $white;
    border-color: $white;
  }

  &.badge-draft {
    background-color: #17a2b8;
    color: $white;
    border-color: $white;
  }

  &.badge-sm {
    padding: 6px 11px;
  }
}

a.badge-tag {
  &:hover, &:active {
    text-decoration: none;
    color: $white;
    background-color: $secondary;
  }
}

.data-table {
  .data-row {
    padding: 15px;
    border: 1px solid #ebeef2;
    background-color: $white;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  a.data-row:hover {
    text-decoration: none;
    background-color: #f8f9fa;
  }
}

.table {
  td {
    border-top: none;
    padding: 1rem;

    &.more-options-menu {
      width: 37px;
      padding: 0;

      a {
        padding: 1rem;
        display: block;
      }
    }
  }

  &.table-sm {
    td {
      padding: 10px;
    }
  }

  &.table-borderless {
    thead {
      th {
        font-weight: normal;
        color: rgba(86, 94, 115, 0.87);
      }
    }
  }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: rgba(120, 121, 147, 0.04);
      }
    }
  }
}

.modal-header {
  background-color: #318ef6;

  * {
    color: $white;
  }
}

.circled {
  width: 34px;
  height: 34px;
  display: block;
  line-height: 28px;
  border: solid 2px #f2f2f5;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.05);
}

.color-swatch {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  display: inline-block;
}

.task-assigned {
  .circled {
    border-color: $green;
  }
}

.content-muted {
  .circled {
    color: $text-muted;
  }

  &.task-assigned {
    .circled {
      border-color: rgba(40, 167, 69, 0.24);
    }
  }

  td,
  .description {
    color: $text-muted;
    text-decoration: $text-muted solid line-through;
  }
}

.task-acted {
  background-color: #ddf1e2!important;
}

.task-postponed {
  background-color: #fff3d0!important;
}

a.text-color {
  color: $body-color;
}

.scroll-y {
  overflow-y: auto;
}

.top-nav {
  a {
    padding: 27px 10px;
    display: block;
    text-align: center;
    color: rgba(37, 38, 94, 0.54);

    &.active,
    &:hover,
    &:active {
      color: $body-color;
      text-decoration: none;
    }

    &.active {
      border-bottom: 4px solid $primary;
    }
  }
}

.react-datepicker-wrapper {
  display: block;
}

.modal-xl {
  .modal-body {
    .pre-scrollable {
      overflow-y: auto;
      white-space: pre-wrap;
      max-width: 350px;
    }
  }
}

.program-status {
  display: inline-block;
  width: .8em;
  height: .8em;
  vertical-align: baseline;
  border-radius: 50%;

  &.green {
    background-color: map-get($colors, 'green');
  }

  &.yellow {
    background-color: map-get($colors, 'yellow');
  }

  &.red {
    background-color:map-get($colors, 'red');
  }

  &.gray {
    background-color: map-get($colors, 'gray');
  }
}

.invoy-calendar {
  position: relative;

  > .card {
    border-color: $body-color;
  }

  .day {
    height: calc(1350px + 2.5rem);

    .calendar-event {
      position: absolute;
      left: .2rem;
      right: .2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 2;
    }
  }

  .bg-slots {
    left: 0;
    right: 0;
    height: 1350px;
    padding: 0;

    > .bg-slot {
      position: absolute;
      width: 100%;

      &.bg-personal {
        left: 0;
        background: url(/blue-inclined-right.svg) top left repeat;
      }

      &.bg-group {
        right: 0;
        background: url(/green-inclined-left.svg) top left repeat;
      }

      .from-time {
        position: absolute;
        top: 0;
        margin-top: -.5rem;
        z-index: 1;
      }

      .to-time {
        position: absolute;
        bottom: 0;
        margin-bottom: -.5rem;
        z-index: 1;
      }
    }
  }

  .current-time {
    height: 1px;
    background: $red;
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;

    .time {
      position: absolute;
      top: -.5rem;
      left: -2.5rem;
    }
  }
}

.popover {
  max-width: 200px;
}

.height-short {
  max-height: 100px;
}

.text-two-line-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-scrolling-overflow {
  display: -webkit-inline-box;
  overflow: scroll;
  max-width: 100px;
}

.filter-button {
  &:not(:disabled), :not(.disabled) {
    &:active, &:focus {
      background-color: unset;
      border-color: unset;
      box-shadow: none;
    }
  }
}

/* FONTS */

.ff-sans-serif {
  font-family: $font-family-sans-serif !important;
}

.ff-serif {
  font-family: $font-family-serif !important;
}

.ff-mono {
  font-family: $font-family-monospace !important;
}

.fs-1 {
  font-size: 2.5rem;
}

.fs-2 {
  font-size: 2rem;
}

.fs-3 {
  font-size: 1.75rem;
}

.fs-4 {
  font-size: 1.5rem;
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}
