.weight-graph {
  --color-text-primary: #283249;
  --color-text-secondary: #787993;
  --color-brand-blue: #007BFF;
  --color-danger: #DC3545;
  --color-success: #28A745;
  --color-warning: #FFC107;
  --color-inactive: #AAAAAA;
  --weight-chart-height: 14px;
  --weight-chart-width: 120px;
  --weight-chart-padding: 2px;

  position: relative;
  width: 180px;
  --result: calc((var(--nominator) / var(--denominator)) * var(--weight-chart-width));

  .weight-chart {
    background-color: #DDDEE4;
    display: inline-block;
    height: var(--weight-chart-height);
    left: calc((100% - var(--weight-chart-width)) / 2);
    padding: var(--weight-chart-padding);
    position: absolute;
    top: calc((100% - var(--weight-chart-height)) / 2);
    width: var(--weight-chart-width);
  }

  .inner-bar {
    background-color: transparent;
    display: block;
    height: calc(var(--weight-chart-height) - (var(--weight-chart-padding) * 2));
    width: calc(100% - var(--result));
  }

  .weight-data {
    font-weight: bold;
  }

  .current-weight {
    font-size: 14px;
    position: absolute;
    text-align: center;
    top: calc(50% - ((var(--weight-chart-height) / 2) + 1.25rem));
    width: 60px;
  }

  .starting-weight, .goal-weight, .decimal {
    font-size: 10px;
  }

  .starting-weight, .goal-weight {
    color: var(--color-text-secondary);
    position: absolute;
    top: calc(50% + (var(--weight-chart-height) / 2));
  }

  /* LOSING WEIGHT*/
  &.losing {
    --nominator: calc(var(--starting-weight) - var(--current-weight));
    --denominator: calc(var(--starting-weight) - var(--goal-weight));

    .inner-bar {
      background-color: var(--color-text-secondary);
    }

    .current-weight {
      right: calc(((100% - (var(--weight-chart-width) + 64px) ) / 2) + var(--result));
    }

    .starting-weight {
      right: calc((100% - var(--weight-chart-width)) / 2);
      text-align: right;
    }

    .goal-weight {
      left: max(calc((100% - var(--weight-chart-width)) / 2), 0);
      text-align: left;
    }
  }

  /* GAINING START */
  &.gaining {
    --nominator: calc(var(--current-weight) - var(--starting-weight));
    --denominator: calc(var(--current-weight) - var(--goal-weight));

    .weight-chart {
      background-color: var(--color-danger);
    }

    .inner-bar {
      background-color: rgba(255,255,255,0.5);
      width: calc(100% - var(--result));
    }

    .current-weight {
      right: calc(((100% - (var(--weight-chart-width) + 64px) ) / 2));
      text-align: center;
    }

    .starting-weight {
      right: calc(((100% - var(--weight-chart-width)) / 2) + var(--result) + (1 * var(--weight-chart-padding)));
      text-align: right;
    }

    .goal-weight {
      left: calc((100% - var(--weight-chart-width)) / 2);
      text-align: left;
    }
  }

  /* UNDER GOAL */
  &.under {
    --nominator: calc(var(--goal-weight) - var(--current-weight));
    --denominator: calc(var(--starting-weight) - var(--current-weight));
    --result: calc((var(--nominator) / var(--denominator)) * (var(--weight-chart-width)) + (2 * var(--weight-chart-padding)));

    .weight-chart {
      background-color: var(--color-success);
      border-color: var(--color-success);
    }

    .inner-bar {
      background-color: rgba(255,255,255,0.5);
      position: absolute;
      right: var(--weight-chart-padding);
      // width: min(calc(100% - var(--result)), var(--weight-chart-width)) - var(--weight-chart-padding);
    }

    .current-weight {
      left: calc((100% - (var(--weight-chart-width) + 20px) ) / 2);
      text-align: left;
    }

    .starting-weight {
      right: calc((100% - var(--weight-chart-width)) / 2);
      text-align: right;
    }

    .goal-weight {
      left: max(calc(((100% - var(--weight-chart-width)) / 2) + var(--result) - var(--weight-chart-padding)), 0);
      text-align: left;
    }
  }

  /* MAINTAIN WEIGHT*/
  &.maintain {
    --nominator: calc(var(--goal-weight) - var(--current-weight));
    --denominator: calc(var(--goal-weight) * .66);

    .current-weight {
      left: min(calc(50% - var(--result) - 26px), var(--weight-chart-width));
      text-align: center;
    }

    .starting-weight {
      dispay: none;
    }

    .goal-weight {
      text-align: center;
      left: 50%;
      width: 40px;
      margin-left: -20px;
    }

    .inner-bar {
      background-color: var(--color-text-secondary);
      height: var(--weight-chart-height);
      left: min(calc(50% - var(--result) - (var(--weight-chart-height) / 2)), var(--weight-chart-width)); /* subtract 1/2 the width */
      position: absolute;
      top: 0;
      width: var(--weight-chart-height);
    }

    .center-bar {
      border-right: 1px dotted var(--color-text-secondary);
      height: var(--weight-chart-height);
      left: 50%;
      position: absolute;
      top: 0;
      width: 0px;
    }
  }
}
