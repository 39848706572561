.client-status {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 450px;
  border-radius: 0;
  border: none;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.14);
  z-index: 1040;

  .card-header {
    background-color: #318ef6;
    border-radius: 0;

    &.vip {
      background-color: #d39e00;
    }

    h6 {
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .card-body {
    .custom-select,
    .form-control {
      text-align: right;
      border-width: 0;
    }
  }
}

