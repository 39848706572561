@font-face {
  font-family: 'Mackinac';
  src: url('/v2/fonts/P22MackinacProBook.woff2') format('woff2'),
    url('/v2/fonts/P22MackinacProBook.woff') format('woff'),
    url('/v2/fonts/P22MackinacProBook.eot') format('eot');
}

@font-face {
  font-family: 'Soehne';
  src: url('/v2/fonts/soehne-web-leicht.woff2') format('woff2'),
    url('/v2/fonts/soehne-web-leicht.woff') format('woff'),
    url('/v2/fonts/soehne-web-leicht.eot') format('eot');
  font-weight: 100 400;
}

@font-face {
  font-family: 'Soehne';
  src: url('/v2/fonts/soehne-web-kraftig.woff2') format('woff2'),
    url('/v2/fonts/soehne-web-kraftig.woff') format('woff'),
    url('/v2/fonts/soehne-web-kraftig.eot') format('eot');
  font-weight: 500 900;
}

@font-face {
  font-family: 'Soehne Mono';
  src: url('/v2/fonts/soehne-mono-leicht.woff2') format('woff2'),
    url('/v2/fonts/soehne-mono-leicht.woff') format('woff'),
    url('/v2/fonts/soehne-mono-leicht.eot') format('eot');
  font-weight: 100 400;
}
