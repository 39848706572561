.grid {
  line {
    stroke: lightgrey;
    stroke-opacity: 0.6;
    shape-rendering: crispEdges
  }
  .grid path {
    stroke-width: 0;
  }
}

.graph-tooltip {
  position: absolute;
  text-align: center;
  background: #fff;
  border-radius: 8px;
  pointer-events: none;
  padding: .65rem;
  box-shadow: 0px 0px 4px 0px #ccc; 
}
